import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"

import Leistungen from "@modules/leistungen"

const Coaching = ({ location, data }: any) => {
  const {
    coaching_startbild_l,
    coaching_startbild_s,
    coaching_startbild_m,
    kundenzufriedenheit_icon,
    kostensenkung_icon,
    mitarbeitermotivation_icon,
    ammerthaler_Institut_Flyer_Coaching,
    outdoor_training_startbild_m,
    fylerPDF,
  } = data

  const coachingL: any = getImage(coaching_startbild_l)
  const coachingM: any = getImage(coaching_startbild_m)
  const coachingS: any = getImage(coaching_startbild_s)
  const flyer: any = getImage(ammerthaler_Institut_Flyer_Coaching)

  return (
    <Layout location={location}>
      <Seo title="Coaching">
        <Leistungen
          sectionH1="Coaching"
          sectionH2="Berufliche Standortbestimmung, Lebensbalance oder 
          der Umgang mit persönlichen und beruflichen Veränderungen: Richten Sie Ihren Kurs aus, wir unterstützen 
          Sie gerne dabei!"
          introImage={coachingL}
          introImageS={coachingS}
          introImageM={coachingM}
          introImageAlt="test"
          intro={[
            {
              title: "Was wir unter Coaching verstehen",
              text: "Ziele von Coaching sind persönliche Stärkung, Reflexion und Standortbestimmung – es geht darum, Stärken zu stärken und Entwicklungsfelder anzugehen. Wir verstehen darunter die lösungs- und zielorientierte Begleitung von Menschen, die die persönliche Weiterentwicklung proaktiv angehen möchten oder in Krisensituationen professionelle Unterstützung in Anspruch nehmen möchten.",
            },
            {
              title: "Wie wir vorgehen",
              text: "Unser Vorgehen basiert auf einem neuropsychologischen und systemischen Ansatz, der die Erkenntnisse der Hirnforschung für die Motivation und Verhaltensänderung von Menschen und Systemen nutzbar macht. Wir begleiten Sie dabei, innezuhalten, den eigenen Standort zu reflektieren und daraus konkrete Handlungsimpulse abzuleiten.",
            },
          ]}
          supportTitle="Wir Unterstützen Sie"
          supportImage={flyer}
          supportImageAlt="test"
          supportUlTitle="Beispiele für Coaching-Anliegen:"
          supportUl={[
            {
              icon: flyer,
              text: "Entwicklung von Führungskompetenzen",
            },
            {
              icon: flyer,
              text: "Umgang mit persönlichen/beruflichen Veränderungen",
            },
            {
              icon: flyer,
              text: "Trainieren der mentalen Stärke",
            },
          ]}
          profitTitle="Wie sie davon profitieren"
          quoteActive
          quote="Im Coaching geht es nicht darum etwas zu lehren, sondern die Voraussetzungen für eigenes Lernen und Wachstum zu schaffen."
          quoteAuthor="- John Whitemore"
          profitUl={[
            {
              icon: kundenzufriedenheit_icon,
              iconAlt: "test",
              text: "Erhöhung der Kundenzufriedenheit",
              key: kundenzufriedenheit_icon.childImageSharp.id,
            },
            {
              icon: mitarbeitermotivation_icon,
              iconAlt: "test",
              text: "Steigende Mitarbeitermotivation",
              key: mitarbeitermotivation_icon.childImageSharp.id,
            },
            {
              icon: kostensenkung_icon,
              iconAlt: "test",
              text: "Kostensenkung",
              key: kostensenkung_icon.childImageSharp.id,
            },
          ]}
          feedback={[
            {
              text: (
                <p>
                  Frau Christine Lehner hat zusammen mit weiteren Trainern für
                  das Jobcenter Dachau im Herbst 2018 ein Teamwochenende
                  durchgeführt. Bei der Planung ist sie auf die Wünsche und
                  Bedarfe sehr gut eingegangen. Resilienz ist ein wichtiger
                  Baustein im Rahmen einer gesundheitsorientieren
                  Mitarbeiterführung. In ihrem Workshop gab sie einen Einblick
                  in mentale Techniken, die dabei unterstützen, auch in
                  unsicheren Situationen souverän zu bleiben. Die Trainerin hat
                  die Teilnehmer zur motivierten Mitarbeit angeregt und
                  erreicht, dass sich Jeder seiner individuellen inneren Stärken
                  bewusst wird und im Bedarf einsetzen kann. Ich kann Christine
                  Lehner speziell für diese Themengebiete sehr als
                  professionelle Beraterin/Trainerin sehr empfehlen.
                </p>
              ),
              author: "Peter Schadl",
              job: "Geschäftsführer Jobcenter Dachau",
            },
            {
              text: (
                <p>
                       Frau Lehner hat für die Lebenshilfe Neumarkt den Prozess der Entwicklung eines gemeinsamen Führungsverständnisses moderiert und begleitet.
Sehr gute Vorbereitung, konkrete Absprachen in der Zusammenarbeit und eine speziell auf das Unternehmen ausgerichtete Strategie der Entwicklung haben den Ablauf kurzweilig und erfolgreich gemacht.
In den jeweiligen Settings sinnvoll angewandte Methoden machten den Entstehungsprozess lebendig und das Thema jederzeit für die Teilnehmenden (be-)greifbar.
Frau Lehner gibt Input, lässt Themen erarbeiten, hört gut zu und stellt sich in professioneller Art und Weise auf die Belange der Zielgruppe in der jeweiligen Situation ein.
Die Erarbeitung des gemeinsamen Führungsverständnisses mit Frau Lehner war für alle Beteiligten eine wertvolle Erfahrung mit einem mehr als zufriedenstellenden Ergebnis.
 

          </p>
              ),
              author: "Stefanie Winkler",
              job: "Vorstand Fachthemen Lebenshilfe Neumarkt",
            },
          ]}
          recommendationTitle="Weitere Punkte unseres Leistungsspektrums"
          recommendataion={{
            outdoor: true,
            seminare_und_workshops: true,
            organisationsentwicklung: true,
            coaching: false,
          }}
          fylerActive
          fylerUrl={fylerPDF.publicURL}
          fylerButtonActive={true}
        />
      </Seo>
    </Layout>
  )
}

export const query = graphql`
  query {
    fylerPDF: file(
      absolutePath: { regex: "/ammerthaler_institut_coaching_flyer/" }
    ) {
      publicURL
    }
    coaching_startbild_l: file(
      relativePath: { regex: "/coaching_startbild_l/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    outdoor_training_startbild_m: file(
      relativePath: { regex: "/outdoor_training_startbild_m/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    coaching_startbild_s: file(
      relativePath: { regex: "/coaching_startbild_s/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    coaching_startbild_m: file(
      relativePath: { regex: "/coaching_startbild_m/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kundenzufriedenheit_icon: file(
      relativePath: { regex: "/kundenzufriedenheit_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kostensenkung_icon: file(relativePath: { regex: "/kostensenkung_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    mitarbeitermotivation_icon: file(
      relativePath: { regex: "/mitarbeitermotivation_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    ammerthaler_Institut_Flyer_Coaching: file(
      relativePath: { regex: "/Ammerthaler_Institut_Flyer_Coaching/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

export default Coaching
